<template>
  <div>
    <div class="columns is-vcentered is-centered">
      <div class="column is-half">
        <div class="title-wrapper">
          <img class="logo" src="../img/logo.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.title-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.logo {
  width: 200px;
}
</style>
