<template>
  <div class="content-box">
    <a :href="link">
      <div class="service-field">
        <div class="service-icon">
          <font-awesome-icon :icon="['fas', icon]" size="2x" />
        </div>
        <div class="service-label">{{ label }}</div>
        <font-awesome-icon
          class="status-circle online"
          :icon="['fas', 'circle']"
        />
      </div>
    </a>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "service-button",
  props: {
    link: {
      type: String,
    },
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  methods: {
    isOnline(url) {
      axios
        .get(url)
        .then((res) => {
          console.log(res.data);
        })
        .catch(error => console.log(error));
    },
  },
  mounted() {
    //this.isOnline(this.link);
  },
};
</script>

<style>
.service-icon {
  display: flex;
  justify-content: center;
  width: 3rem;
}

.service-field {
  padding: 1rem;
  display: flex;
  align-items: center;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}

.service-label {
  padding-left: 2rem;
}

.chevron {
  margin-left: 1rem;
}

.status-circle {
  margin-left: auto;
}

.offline {
  color: red;
}

.online {
  color: greenyellow;
}
</style>