<template>
  <div id="app">
    <!-- Header -->
    <my-header></my-header>
    <!-- Menu -->
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <div class="menu">
          <collapsable class="collapsable" title="Öffentlich" icon="lock-open">
            <template #content>
              <profile></profile>
            </template>
          </collapsable>
        </div>
      </div>
    </div>
    <div class="has-top-bottom-margin has-text-centered">
      <a @click="openImpress">Impressum</a>
    </div>
  </div>
</template>

<script>
import MyHeader from "./components/MyHeader";
import Collapsable from "./components/Collapsable";
import ServiceButton from "./components/ServiceButton";
import MediaList from "./components/MediaList";

// Statics
import Impress from "./static/Impress";
import Profile from "./static/Profile";
//data
import Services from "./data/services.json";

export default {
  name: "App",
  data() {
    return { services: Services.services };
  },
  components: { MyHeader, Collapsable, Profile, ServiceButton, MediaList },
  methods: {
    login() {
      this.$auth.loginWithPopup();
    },
    async openImpress() {
      this.$buefy.modal.open({
        parent: this,
        component: Impress,
        hasModalCard: true,
        customClass: "",
        trapFocus: true,
        scroll: "keep",
      });
    },
  },
};
</script>

<style lang="scss">
@import "style.scss";

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 3em;
}

html {
  height: 100%;
}

/* Classes */

.mediaList {
  margin-top: 1rem;
}

.collapsable {
  margin-top: 1rem;
}

.service-logo {
  width: 3rem;
}

.service-field {
  padding: 1rem;
  display: flex;
  align-items: center;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}

.service-field:hover {
  border-color: rgb(119, 119, 119);
}

.service-field-header {
  font-size: xx-small;
  font-weight: bold;
  margin-bottom: 5px;
}

.service-field-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.service-field-wrapper-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.contact-logo {
  width: 2rem;
  margin-left: 5px;
}

.title-wrapper {
  display: flex;
  justify-content: center;
}

.service-label {
  padding-left: 2rem;
}

.content {
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  width: 3rem;
}

.profile-image {
  border-radius: 50%;
  width: 5rem;
}

.profile-text {
  margin-top: 10px;
  font-weight: lighter;
  font-style: italic;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.impress-header {
  font-weight: bold;
}

.overflow-y {
  overflow-y: auto;
}

.menu {
  margin: 2rem 2rem 0 2rem;
}

.has-top-bottom-margin{
  margin: 2rem 0 2rem 0;
}
</style>
