<template>
  <div>
    <b-collapse
      class="card"
      animation="slide"
      :open="open"
      aria-id="contentIdPublic"
    >
      <template>
        <div class="card-header" role="button" aria-controls="contentIdPublic">
          <p class="card-header-title">
            <font-awesome-icon :icon="['fas', icon]" /><span
              style="padding-left: 1rem"
              >{{ title }}</span
            >
          </p>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <slot name="content"></slot>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "...",
    },
    icon: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: true,
    }
  },
};
</script>

<style scoped>

</style>
