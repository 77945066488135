import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import VueRouter from 'vue-router'

// Import Auth0
import { domain, clientId } from "./auth/auth-config.json";
import { Auth0Plugin } from "./auth";

// Import FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLockOpen, faEnvelope, faChevronDown, faChevronRight, faUserLock, faCircle, faFilm, faCogs, faCamera, faPhotoVideo } from '@fortawesome/free-solid-svg-icons'
import { faXing, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faLockOpen, faXing, faLinkedin, faGithub, faEnvelope, faChevronDown, faChevronRight, faUserLock, faCircle, faFilm, faCogs, faCamera, faPhotoVideo);

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

// Plugins
Vue.use(Buefy)
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    onRedirectCallback: appState => {
        VueRouter.push(
            appState && appState.targetUrl ?
            appState.targetUrl :
            window.location.pathname
        );
    }
});
Vue.use(VueRouter)

new Vue({
    render: h => h(App),
}).$mount('#app')