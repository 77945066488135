<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">
        <font-awesome-icon :icon="['fas', 'photo-video']" /><span
          style="padding-left: 1rem"
          >Latest Media</span
        >
      </p>
    </header>
    <div class="card-content">
      <div class="content">
        <iframe
          height="300"
          src="https://www.youtube.com/embed/kEcvyXm2wnY"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.content {
  display: flex;
  justify-content: center;
}
</style>